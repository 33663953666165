
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SignIn",
  components: {
    Form,
    Field,
    ErrorMessage,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const submitButton = ref<HTMLElement | null>(null);
    const { handleSubmit } = useForm();

    const isNameRequired = (value) => {
      if (!value) {
        return t("ruser");
      }
      return true;
    };

    const isPasswordRequired = (value) => {
      if (!value) {
        return t("rpasswordrequired");
      }
      if (value.length < 4) {
        return t("rpassword");
      }
      return true;
    };

    const onSubmit = (value) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      setTimeout(() => {
        store.dispatch(Actions.LOGIN, value).then(() => {
          store.dispatch("myPermissions");
          store.dispatch(Actions.MY_PROFILE);
          Swal.fire({
            text: "Se ha autenticado satisfactoriamente.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            router.push({ name: "dashboard" });
          });
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    onMounted(() => {
      if (store.getters.isUserAuthenticated) {
        router.push({ name: "dashboard" });
      }
    });

    return {
      email,
      password,
      submitButton,
      onSubmit,
      isNameRequired,
      isPasswordRequired,
    };
  },
});
